define("discourse/plugins/discourse-presence/discourse/components/topic-presence-display", ["exports", "discourse-common/utils/decorators", "@ember/component", "@ember/object/computed", "@ember/service"], function (_exports, _decorators, _component, _computed, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    topic: null,
    presence: (0, _service.inject)(),
    replyChannel: null,
    whisperChannel: null,
    replyUsers(users) {
      return users?.filter(u => u.id !== this.currentUser.id);
    },
    whisperUsers(users) {
      return users?.filter(u => u.id !== this.currentUser.id);
    },
    users: (0, _computed.union)("replyUsers", "whisperUsers"),
    replyChannelName(id) {
      return `/discourse-presence/reply/${id}`;
    },
    whisperChannelName(id) {
      return `/discourse-presence/whisper/${id}`;
    },
    shouldDisplay: (0, _computed.gt)("users.length", 0),
    didReceiveAttrs() {
      this._super(...arguments);
      if (this.replyChannel?.name !== this.replyChannelName) {
        this.replyChannel?.unsubscribe();
        this.set("replyChannel", this.presence.getChannel(this.replyChannelName));
        this.replyChannel.subscribe();
      }
      if (this.currentUser.staff && this.whisperChannel?.name !== this.whisperChannelName) {
        this.whisperChannel?.unsubscribe();
        this.set("whisperChannel", this.presence.getChannel(this.whisperChannelName));
        this.whisperChannel.subscribe();
      }
    },
    _destroyed() {
      this.replyChannel?.unsubscribe();
      this.whisperChannel?.unsubscribe();
    }
  }, [["method", "replyUsers", [(0, _decorators.default)("replyChannel.users.[]")]], ["method", "whisperUsers", [(0, _decorators.default)("whisperChannel.users.[]")]], ["method", "replyChannelName", [(0, _decorators.default)("topic.id")]], ["method", "whisperChannelName", [(0, _decorators.default)("topic.id")]], ["method", "_destroyed", [(0, _decorators.on)("willDestroyElement")]]]));
});