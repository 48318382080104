define("discourse/plugins/discourse-presence/discourse/components/composer-presence-display", ["exports", "discourse-common/utils/decorators", "@ember/object/computed", "@ember/component", "@ember/service"], function (_exports, _decorators, _computed, _component, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    tagName: "",
    presence: (0, _service.inject)(),
    composerPresenceManager: (0, _service.inject)(),
    state(replyingToTopic, editingPost, whisper, composerOpen) {
      if (!composerOpen) {
        return;
      } else if (editingPost) {
        return "edit";
      } else if (whisper) {
        return "whisper";
      } else if (replyingToTopic) {
        return "reply";
      }
    },
    isReply: (0, _computed.equal)("state", "reply"),
    isEdit: (0, _computed.equal)("state", "edit"),
    isWhisper: (0, _computed.equal)("state", "whisper"),
    replyChannelName(topicId, isReply, isWhisper) {
      if (topicId && (isReply || isWhisper)) {
        return `/discourse-presence/reply/${topicId}`;
      }
    },
    whisperChannelName(topicId, isReply, isWhisper) {
      if (topicId && this.currentUser.staff && (isReply || isWhisper)) {
        return `/discourse-presence/whisper/${topicId}`;
      }
    },
    editChannelName(isEdit, postId) {
      if (isEdit) {
        return `/discourse-presence/edit/${postId}`;
      }
    },
    _setupChannel(channelKey, name) {
      if (this[channelKey]?.name !== name) {
        this[channelKey]?.unsubscribe();
        if (name) {
          this.set(channelKey, this.presence.getChannel(name));
          this[channelKey].subscribe();
        } else if (this[channelKey]) {
          this.set(channelKey, null);
        }
      }
    },
    _setupChannels() {
      this._setupChannel("replyChannel", this.replyChannelName);
      this._setupChannel("whisperChannel", this.whisperChannelName);
      this._setupChannel("editChannel", this.editChannelName);
    },
    _cleanupChannels() {
      this._setupChannel("replyChannel", null);
      this._setupChannel("whisperChannel", null);
      this._setupChannel("editChannel", null);
    },
    replyingUsers: (0, _computed.union)("replyChannel.users", "whisperChannel.users"),
    editingUsers: (0, _computed.readOnly)("editChannel.users"),
    presenceUsers(isReply, replyingUsers, editingUsers) {
      const users = isReply ? replyingUsers : editingUsers;
      return users?.filter(u => u.id !== this.currentUser.id)?.slice(0, this.siteSettings.presence_max_users_shown);
    },
    shouldDisplay: (0, _computed.gt)("presenceUsers.length", 0),
    subscribe() {
      this._setupChannels();
    },
    _contentChanged() {
      if (this.model.reply === "") {
        return;
      }
      const entity = this.state === "edit" ? this.model?.post : this.model?.topic;
      this.composerPresenceManager.notifyState(this.state, entity?.id);
    },
    closeComposer() {
      this._cleanupChannels();
      this.composerPresenceManager.leave();
    }
  }, [["method", "state", [(0, _decorators.default)("model.replyingToTopic", "model.editingPost", "model.whisper", "model.composerOpened")]], ["method", "replyChannelName", [(0, _decorators.default)("model.topic.id", "isReply", "isWhisper")]], ["method", "whisperChannelName", [(0, _decorators.default)("model.topic.id", "isReply", "isWhisper")]], ["method", "editChannelName", [(0, _decorators.default)("isEdit", "model.post.id")]], ["method", "_setupChannels", [(0, _decorators.observes)("replyChannelName", "whisperChannelName", "editChannelName")]], ["method", "presenceUsers", [(0, _decorators.default)("isReply", "replyingUsers.[]", "editingUsers.[]")]], ["method", "subscribe", [(0, _decorators.on)("didInsertElement")]], ["method", "_contentChanged", [(0, _decorators.observes)("model.reply", "state", "model.post.id", "model.topic.id")]], ["method", "closeComposer", [(0, _decorators.on)("willDestroyElement")]]]));
});